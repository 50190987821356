import React from "react";

import Layout from "../components/layout";
import PageLegal from "../components/page-legal";

const NotFoundPage = () => (
  <Layout
    seo={{
      title: "404: Page Not Found",
      desc: "The page you are looking for could not be found."
    }}
  >
    <PageLegal
      pageCat={404}
      title="Page Not Found"
      html={"<p>The page you are looking for could not be found.</p>"}
    />
  </Layout>
);

export default NotFoundPage;
